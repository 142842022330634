import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function MapboxRobot(props) {

  const navigate =useNavigate()
  mapboxgl.accessToken =
  "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

  const robotlinecolor=['#3246B2','#53B6CC','#E68D40','#74CB6B','#D04622','#292F40']



    const robotMarkerDiv = document.createElement("div");
    robotMarkerDiv.style.width = "35px";
    robotMarkerDiv.style.height = "35px";
    robotMarkerDiv.style.backgroundImage =
      "url(/assets/images/robot-icon-main.svg)";
    robotMarkerDiv.style.borderRadius = "100%";
    robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";

    
    const dropMarkerDiv = document.createElement("div");
    dropMarkerDiv.style.width = "35px";
    dropMarkerDiv.style.height = "35px";
    dropMarkerDiv.style.backgroundImage =
        "url(/assets/images/robot_dropOff_main.svg)";
    dropMarkerDiv.style.borderRadius = "100%";
    dropMarkerDiv.style.animation = "example 1s ease-in-out infinite";
    
    const pcikupMarkerDiv = document.createElement("div");
    pcikupMarkerDiv.style.width = "35px";
    pcikupMarkerDiv.style.height = "35px";
    pcikupMarkerDiv.style.backgroundImage =
        "url(/assets/images/PickUpLocation-icon.svg)";
    pcikupMarkerDiv.style.borderRadius = "100%";
    pcikupMarkerDiv.style.animation = "example 1s ease-in-out infinite";


   const [markerArray, setmarkerArray] =useState([])
   const [map, setMap] = useState(null);
     const [zoom, setZoom] = useState(15);

 let offset = 0.00003;
  useEffect(()=>{
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [
          props.robotLocation?.lon,
          props.robotLocation?.lat
        ],
        zoom: 16,
      });

      const dropMarker = new mapboxgl.Marker(dropMarkerDiv)
      .setLngLat([
       props.orderDropLocation.lon,
       props.orderDropLocation.lat,
      ])
      .addTo(newMap);
       const pickupMarker = new mapboxgl.Marker(pcikupMarkerDiv)
       .setLngLat([
        props.orderPickupLocation.lon,
        props.orderPickupLocation.lat,
       ])
       .addTo(newMap);

       const newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
       .setLngLat([
        props.robotLocation.lon,
        props.robotLocation.lat,
       ])
       .addTo(newMap);
      
      
      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });
       setMap(newMap);
    };
    if (!map) {
      initializeMap();
    }
  
  },[map,setMap])

 
 
  return (
   
   <div style={{height:'400px'}}>

       <div
          id="map-container-id"
          // ref={mapContainer}
          className="map-container"
          style={{ height: "100%" }}>
       </div> 
    </div>
  )
}

export default MapboxRobot
