import React, { useEffect } from 'react'
import Header from './Header'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
function OrderDelivered() {

    const orderDetails = useLocation()
    useEffect(()=>{
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10){
          dd = '0' + dd;
        } 
        if (mm < 10){
          mm = '0' + mm;
        } 
        const formattedToday = mm + '-' + dd + '-' + yyyy 
        document.getElementById('DATE').innerHTML = formattedToday;
    },[])


  return (
    <>
    <div className='MainWhole-wrapper-container'>  
    {/* {console.log('orderIdorderId',orderData)} */}
   <Header />
   {console.log('orderDetails',orderDetails)}
   <div className='Congratulation-Main-wrapper' style={{paddingTop:'66px'}}>
        <div className="OrderDelivered_page_thankYou_div_wrapper"> 
                <h3> THANK YOU</h3>
        </div>
        <div className='OrderDelivered_page_tick_div'> 
            <img src="/assets/images/noun-tick-317777.svg"/>
        </div>
        <div> 
         <h3 className='OrderDelivered_page_h3'> Order Delivered</h3>
        </div> 
   <Timeline position="alternate" style={{marginTop:'0px'}}>
      <TimelineItem>
         <TimelineSeparator>
           <TimelineDot variant="outlined" className='timeline_dot_red'/>
           <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent></TimelineContent>
      </TimelineItem>
        <div className='OrderDelivered_page_content_div'> 
           <h4>Order ID </h4>
           <p> {orderDetails?.state?.orderID}</p>
        </div> 
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent></TimelineContent>
      </TimelineItem>
      <div className='OrderDelivered_page_content_div'> 
           <h4>Date </h4>
           <p id="DATE"> </p>
        </div>
    </Timeline>  
   </div>
  </div>
  <Footer/>
  </>
  )
}

export default OrderDelivered
