import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import '../src/CSS/custom.css'
import Congratulations from './components/Congratulations';
import OrderCancel from './components/OrderCancel';
import OrderDelivered from './components/OrderDelivered';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  
  return (
    <div className="App">
       <Routes>
          <Route path="/" element={<Congratulations/>}/>  
          <Route path="/ordercancel" element={<OrderCancel/>}/> 
          <Route path="/orderdelivered" element={<OrderDelivered/>}/>   
       </Routes>
    </div>
  );
}

export default App;
