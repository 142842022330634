import { FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18next from 'i18next';
import { useEffect } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
function Header(props) {

  const[currentlanguage, setcurrentlanguage] =useState("Italian")
  const language = localStorage.getItem('language')
  const handlelogoutbtn=()=>{
	   localStorage.setItem('email',null)
       localStorage.setItem('password',null)
	   localStorage.setItem('language', null)
  }
  const navigate= useNavigate()
  const handleBackbutton=()=>{
	navigate(-1)
  } 
  const [isscroll, setisscroll]=useState(false)
  useEffect(()=>{
	window.addEventListener('Scroll', setisscroll(!isscroll));
  },[])
   return (
    <div className={props.open || props.opentwo ? 'MainHeader-wrapper_copy headerscroll':'MainHeader-wrapper'}>
		{/* {console.log('isscroll',isscroll)} */}
       <div className='MainHeader-logo-div-wrapper'>
	   <div className='MainHeader-logo-div '>

		      <Link to="/" onClick={handlelogoutbtn}><img src="/assets/images/Bistrostack_Logo.png" />
		     </Link>
	   </div>  
       {/* <div className='MainHeader_second_div '>
          <div><PersonOutlineOutlinedIcon/> </div>
		    <div> <h5>Ritukar Vijay </h5>
		        <p> +1 213 784 9597</p>
		  </div>
      </div> */}
	 </div>   
    </div>
  )
}

export default Header